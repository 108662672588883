define("discourse/plugins/user-autonomy-plugin/discourse/components/modal/request-op-admin-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/conditional-loading-spinner", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _conditionalLoadingSpinner, _dButton, _dModal, _dModalCancel, _ajax, _ajaxError, _i18n, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RequestTopicOpAdminForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "reason", [_tracking.tracked], function () {
      return "";
    }))();
    #reason = (() => (dt7948.i(this, "reason"), void 0))();
    get topic() {
      return this.args.model.topic;
    }
    get textTemplate() {
      return _I18n.default.t("topic_op_admin.apply_modal.apply_template").replaceAll("#", `[${this.topic.title}](${this.topic.url})`) + `\n${_I18n.default.t("topic_op_admin.apply_modal.apply_reason")}\n`;
    }
    updateValue(event1) {
      event1.preventDefault();
      this.reason = event1.target.value;
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
    close() {
      this.args.closeModal();
    }
    static #_7 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
    submit() {
      this.loading = true;
      (0, _ajax.ajax)("/topic_op_admin/request_for_topic_op_admin", {
        method: "POST",
        data: {
          id: this.topic.id,
          raw: `${this.textTemplate}${this.reason}`
        }
      }).then(res1 => {
        this.close();
        this.dialog.alert(res1.message);
      }).finally(() => {
        this.loading = false;
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_8 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    showComposer() {
      this.composer.openNewMessage({
        recipients: this.currentUser.op_admin_form_recipients.join(","),
        title: _I18n.default.t("topic_op_admin.apply_modal.apply_template_title").replaceAll("#", this.topic.title),
        body: `${this.textTemplate}${this.reason}`,
        hasGroups: true
      });
      this.close();
    }
    static #_9 = (() => dt7948.n(this.prototype, "showComposer", [_object.action]))();
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <form class="request-op-admin-form">
          <DModal
            @title={{i18n "topic_op_admin.apply_for_op_admin"}}
            @closeModal={{@closeModal}}
          >
            <:body>
              <section class="reason">
                <h3>
                  {{i18n "topic_op_admin.apply_modal.introduce_title"}}
                </h3>
                <p>
                  {{i18n "topic_op_admin.apply_modal.what_is"}}
                </p>
                <hr />
              </section>
              <div class="control-group">
                <p>
                  {{i18n "topic_op_admin.apply_modal.tell_reason"}}
                </p>
    
                <textarea
                  {{on "input" this.updateValue}}
                  min-width="500px"
                  min-height="400px"
                  max-height="1000px"
                  resize="both"
                />
              </div>
            </:body>
            <:footer>
              <DButton
                @class="btn-primary"
                @label="groups.membership_request.submit"
                @action={{this.submit}}
                @disabled={{this.loading}}
              />
              <DModalCancel @close={{@closeModal}} />
              <ConditionalLoadingSpinner
                @size="small"
                @condition={{this.loading}}
              />
              <div class="dock-right" style="margin-left: auto;margin-right: 0;">
                <DButton
                  @class="btn-link"
                  @label="topic_op_admin.apply_modal.show_composer"
                  @action={{this.showComposer}}
                />
              </div>
            </:footer>
          </DModal>
        </form>
      
    */
    {
      "id": "5kaOCh89",
      "block": "[[[1,\"\\n    \"],[10,\"form\"],[14,0,\"request-op-admin-form\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"topic_op_admin.apply_for_op_admin\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n          \"],[10,\"section\"],[14,0,\"reason\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"topic_op_admin.apply_modal.introduce_title\"],null]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,2],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"topic_op_admin.apply_modal.what_is\"],null]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"hr\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n            \"],[10,2],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"topic_op_admin.apply_modal.tell_reason\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[11,\"textarea\"],[24,\"min-width\",\"500px\"],[24,\"min-height\",\"400px\"],[24,\"max-height\",\"1000px\"],[24,\"resize\",\"both\"],[4,[32,2],[\"input\",[30,0,[\"updateValue\"]]],null],[12],[13],[1,\"          \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[32,3],null,[[\"@class\",\"@label\",\"@action\",\"@disabled\"],[\"btn-primary\",\"groups.membership_request.submit\",[30,0,[\"submit\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n          \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n          \"],[8,[32,5],null,[[\"@size\",\"@condition\"],[\"small\",[30,0,[\"loading\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"dock-right\"],[14,5,\"margin-left: auto;margin-right: 0;\"],[12],[1,\"\\n            \"],[8,[32,3],null,[[\"@class\",\"@label\",\"@action\"],[\"btn-link\",\"topic_op_admin.apply_modal.show_composer\",[30,0,[\"showComposer\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/user-autonomy-plugin/discourse/components/modal/request-op-admin-form.js",
      "scope": () => [_dModal.default, _i18n.default, _modifier.on, _dButton.default, _dModalCancel.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = RequestTopicOpAdminForm;
});